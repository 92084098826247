import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const Solutions = () => {
  const data = useStaticQuery(graphql`
    query imgQuery {
      allMdx(filter: {frontmatter: {type: {eq: "solutions"}}}) {
        edges {
          node {
            frontmatter {
              title
              caption
              glyph
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  return (
    <div className="Solutions">
      <Row className="solution-widget">
        {data.allMdx.edges.map((edge, i) => (
          <Col key={i} lg="4" sm="6" style={{marginBottom: '1.5rem'}}>
            <Container>
              <Link to={edge.node.fields.slug}>
                <FontAwesomeIcon 
                  className="d-block"
                  icon={`${edge.node.frontmatter.glyph}`}
                />
                <h3>{edge.node.frontmatter.title}</h3>
                {edge.node.frontmatter.caption !== null && (
                  <p>{edge.node.frontmatter.caption}</p>
                )}
              </Link>
            </Container>
          </Col>
        ))}
      </Row>
    </div>
  )
};

export default Solutions;