import React from "react"
import SEO from "components/seo";
import classNames from "classnames";
import { graphql } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col } from "react-bootstrap";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Solutions from "components/Solutions";
import StaticBackgroundImage from "components/StaticBackgroundImage";

export const Index = ({data}) => {
  const { site, sections} = data;
  const { siteMetadata } = site;
  const sectionsData = sections.edges;


  const refs = React.useRef([...sectionsData.map(() => React.createRef())]);
  React.useEffect(() => {
    let observer
    if (refs.current?.filter((e) => e !== undefined).length === sectionsData.length) {
      const options = {
        threshold: 0.5,
      }
      observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const mainNav = document.querySelector(
            `#mainNav`,
            );
            const navElement = document.querySelector(
              `a[href="/#${entry.target.id}"]`,
              );
          if(entry.isIntersecting && entry.target.id === 'section0'){
            mainNav.classList.remove('navbar-shrink');
          }else{
            mainNav.classList.add('navbar-shrink');
          }
          if (navElement && entry.isIntersecting) {
            if (!navElement.classList.contains('active')) {
              navElement.classList.add('active')
            }
          } else if (navElement && navElement.classList.contains('active')) {
            navElement.classList.remove('active')
          }
        })
      }, options);
      refs.current.length && refs.current.map((r) => observer.observe(r.current));
    }
    return () => {
      const mainNavItems = document.querySelectorAll(`#mainNav a.nav-link`);
      const currentPath = window && window.location.pathname;
      mainNavItems.forEach((item) => {
        if(item.classList.contains('active') && item.href.indexOf(currentPath) === -1){
          item.classList.remove('active')
        }
      });
      observer.disconnect()

    }
  }, [refs, sectionsData.length])


  return (
    <div>
      <SEO title={siteMetadata.title} description={siteMetadata.description}  bodyAttributes={{class: 'home'}}/>
            {sectionsData.map((section, i)=> (
              <React.Fragment key={i}>
                {i === 0 ? (
                  <header ref={refs.current[i]} key={i} id={section.node.slug} className={classNames({'improve-contrast' : section.node.frontmatter.improvecontrast, 'invert-contrast': section.node.frontmatter.invertcontrast})}>
                    <StaticBackgroundImage image={section.node.frontmatter.background}>
                      <div id="intro-body">
                        <Container key={i}>
                          <Row>
                            <Col lg="10" className="mx-auto">
                              <div className={classNames({"improve-contrast-box": section.node.frontmatter.improvecontrast})}>
                                <MDXRenderer>
                                  {section.node.body}
                                </MDXRenderer>
                              </div>
                              <a href={`#${sections.edges[i+1].node.slug}`} onClick={(e) => {e.preventDefault(); scrollTo(`#${sections.edges[i+1].node.slug}`)}} className="btn btn-circle" aria-label="Scroll to Next Section">
                                <FontAwesomeIcon 
                                  className="animated"
                                  icon={`angle-double-down`}
                                />
                                </a>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </StaticBackgroundImage>
                  </header>
                ) : (
                  <section ref={refs.current[i]} key={i} id={section.node.slug} className={classNames("backgroundable", "text-center", section.node.slug, {'improve-contrast' : section.node.frontmatter.improvecontrast, 'invert-contrast': section.node.frontmatter.invertcontrast, "backgrounded" : section.node.frontmatter.background})}>
                    <StaticBackgroundImage image={section.node.frontmatter.background}>
                      <Container>
                        <Row>
                          <Col lg="10" className="mx-auto">
                          <MDXRenderer >
                            {section.node.body}
                          </MDXRenderer>
                          {section.node.frontmatter.layout === 'section-solutions' && (
                            <Solutions />
                          )}
                          </Col>
                        </Row>
                      </Container>
                    </StaticBackgroundImage>
                  </section>
                )}
              </React.Fragment>
            ))}
    </div>
  );
};

export const pageQuery = graphql`
  query {
    sections: allMdx(limit: 1000, sort: {order: ASC, fields: [frontmatter___weight]}, filter: {frontmatter: {type: {eq: "sections"}}}) {
      edges {
        node {
          body
          fields {
            layout
          }
          frontmatter {
            title
            type
            menu
            improvecontrast
            invertcontrast
            is_solutions
            layout
            background: image{
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, breakpoints: [1024, 1280, 1440, 2500])
              }
            }
          }
          slug
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;


export default Index;
